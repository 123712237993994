import { ENABLE_EASY_REFILL } from 'gatsby-env-variables';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';
import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';

import './easy-refill.style.scss';
import { graphql, navigate } from 'gatsby';
import PageSection from 'ui-kit/page-section/page-section';
import GatsbyImage from 'gatsby-image';
import { getImage } from 'gatsby-plugin-image';
import { EasyRefillForm } from 'components/easy-refill-form';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
    easyRefillActions,
    easyRefillGetSecretKeyHashRoutine,
    easyRefillVerifyUserRoutine
} from 'state/easy-refill/easy-refill.reducer';
import { formatBirthDate } from 'util/string';
import { VerifyUserResponse } from 'types/easy-refill';
import {
    easyRefillSecurityTokenSelector,
    easyRefillUserBearerTokenSelector
} from 'state/easy-refill/easy-refill.selectors';
import { refreshUnauthenticatedSession } from 'state/account/account.reducers';

export const EasyRefillErrorModal = ({
    translation,
    errorMessage
}: {
    translation: TFunction<'translation'>;
    errorMessage: string;
}) => {
    return <BirdiModalContentAlt subTitle={errorMessage} />;
};

const EasyRefill = ({ location, data }: { location: Location; data: GatsbyTypes.GetLanguageAndImageDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { easyRefillMan } = data;
    const securityToken = useSelector(easyRefillSecurityTokenSelector);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const params = new URLSearchParams(location.search);
    const securityTokenParameter = params.get('securityToken'); //TODO: name could change

    useEffect(() => {
        // if the feature is disabled - take the user to the 404 page
        if (!ENABLE_EASY_REFILL) {
            navigate('/404');
        }
    }, []);

    useEffect(() => {
        if (securityTokenParameter) {
            dispatch(easyRefillActions.setSecurityToken({ securityToken: securityTokenParameter }));
        } else {
            navigate('/link-expired');
        }
    }, [dispatch, securityTokenParameter]);

    // Once security token is set in redux, get the secret key hash value to be used when authenticating (after verification)
    useEffect(() => {
        if (securityToken) {
            dispatch(
                easyRefillGetSecretKeyHashRoutine.trigger({
                    onFailure: () => {
                        navigate('/link-expired');
                    }
                })
            );
        }
    }, [dispatch, securityToken]);

    const handleShowErrorModal = useCallback(
        (error: VerifyUserResponse) => {
            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: <EasyRefillErrorModal translation={t} errorMessage={error.messageText} />,
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'EasyRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmit = useCallback(
        (formData) => {
            const { dobYear, dobMonth, dobDay, zipcode } = formData;
            setIsSubmitting(true);
            dispatch(
                easyRefillVerifyUserRoutine.trigger({
                    dateOfBirth: formatBirthDate(dobYear, dobMonth, dobDay),
                    zipcode: zipcode,
                    onSuccess: () => {
                        dispatch(refreshUnauthenticatedSession());
                    },
                    onFailure: (error: VerifyUserResponse) => {
                        setIsSubmitting(false);
                        handleShowErrorModal(error);
                    },
                    onMaxNumAttemptsReached: () => {
                        setIsSubmitting(false);
                        navigate('/verification-failed');
                    }
                })
            );
        },
        [dispatch, handleShowErrorModal]
    );

    const easyRefillBearerToken = useSelector(easyRefillUserBearerTokenSelector);
    useEffect(() => {
        if (easyRefillBearerToken) {
            navigate('/easy-refill/prescriptions');
        }
    }, [easyRefillBearerToken]);

    return (
        <MarketingPageLayout metaData={{}}>
            <PageSection>
                <Container fluid className={`easy-refill`} id={'easy-refill'}>
                    <Row>
                        <Col sm="12" lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col lg="8" className="easy-refill-pice-form-container">
                                    <h5 className="h5 easy-refill-eyebrow text-center text-lg-left">
                                        {t('pages.easyRefill.userVerification.eyebrow')}
                                    </h5>
                                    <h2 className="easy-refill-title text-center text-lg-left">
                                        {t('pages.easyRefill.userVerification.title')}
                                    </h2>

                                    <div className="spacer text-center text-lg-left mx-auto mx-lg-0 d-block">
                                        &nbsp;
                                    </div>

                                    <h4 className="h4 easy-refill-form-instructions text-center text-lg-left">
                                        {t('pages.easyRefill.userVerification.instructions')}
                                    </h4>

                                    <EasyRefillForm
                                        onSubmit={(e) => handleSubmit(e)}
                                        formName="EasyRefillForm"
                                        submittingForm={isSubmitting}
                                    />
                                </Col>
                                <Col
                                    className="easy-refill-image-container"
                                    xs={{ span: 8, offset: 2 }}
                                    sm={{ span: 6, offset: 3 }}
                                    lg={{ span: 4, offset: 0 }}
                                >
                                    {easyRefillMan && (
                                        <GatsbyImage
                                            fluid={easyRefillMan.childImageSharp.fluid}
                                            image={getImage(easyRefillMan)}
                                            alt={'easy-refill-form-image'}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default EasyRefill;

export const query = graphql`
    query GetLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        easyRefillMan: file(relativePath: { eq: "assets/images/man-looking-at-phone.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
